 
export const catImgs = {
    "E-CLIMATE-CHANGE-AND-CARBON-EMISSION": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-4.png",
    "E-CLIMATE-CHANGE": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-2.png",
    "E-SAVE-THE-MAMMALS": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-3.png",
    "E-CLEAN-FORESTS": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-5.png",
    "G-VOTING-SYSTEM": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-7.png",
    "E-HUMAN-RIGHTS": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-8.png",
    "S-COMMUNITY-RELATIONS": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed.png",
    "S-WOMEN-INCLUSION": "https://esgrowesg.s3.eu-west-2.amazonaws.com/unnamed-6.png"
} 
